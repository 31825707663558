.shepherd-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e1e1e1;
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50%, .75);
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color .5s ease
}

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50%, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

.shepherd-title {
  color: rgba(0, 0, 0, .75);
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  padding: 0
}

.shepherd-header {
  border-bottom: 1px solid #e1e1e1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 2em;
  padding: .75rem .75rem 0;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  padding: 1em
}

.shepherd-text {
  background-color: #f8fafb;
  color: rgba(0, 0, 0, .75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: .75em
}

.shepherd-text p {
  margin-top: 0
}

.shepherd-text p:last-child {
  margin-bottom: 0
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;

  &> :first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &> :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 1050
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden
}

.shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
  box-sizing: border-box
}

.shepherd-arrow, .shepherd-arrow:before {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1
}

.shepherd-arrow:before {
  background: #fff;
  content: "";
  transform: rotate(45deg)
}

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px
}

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px
}

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px
}

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: #ffffff;
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 1045
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transform: translateZ(0);
  transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all
}

.shepherd-tour-step {
  &.sm {
    max-width: 400px !important;
  }
  &.normal {
    max-width: 600px !important;
  }
  &.lg {
    max-width: 800px !important;
  }
}
